import { useLocation } from "@gatsbyjs/reach-router";
import React, { useContext, useMemo } from "react";
import { Helmet } from "react-helmet";

import { UiStateContext } from "../../contexts/ui-state/UiStateContext";
import "./clearstep-widget.scss";

export type ClearstepProps = {
  clearStepSource?: string;
  clearStepResizeScript?: string;
};

const ClearstepWidget: React.FC<ClearstepProps> = ({ clearStepSource, clearStepResizeScript }) => {
  const [hasMounted, setHasMounted] = React.useState(false);
  const uiStateContext = useContext(UiStateContext);
  const hideWidget = uiStateContext?.megaMenuOpen || uiStateContext?.menuOpen;
  const location = useLocation();

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  const currentPageUrl = location?.href ? location.href : "not-set";

  return (
    <>
      <div className={`clearstep-iframe ${hideWidget ? "hide" : ""}`}>
        {useMemo(
          () => (
            <>
              {hasMounted && clearStepSource && clearStepResizeScript && (
                <>
                  <Helmet>
                    <script id="clearstep-resize-script" type="text/javascript" src={clearStepResizeScript}></script>
                  </Helmet>
                  <iframe
                    id="widget-iframe"
                    src={`${clearStepSource}${clearStepSource.includes("?") ? "&" : "?"}parentUrl=${currentPageUrl}`}
                    title="Clearstep Symptom Check Widget"
                    style={{ width: 0, height: 0 }}
                  ></iframe>
                </>
              )}
            </>
          ),
          [clearStepResizeScript, clearStepSource, currentPageUrl, hasMounted]
        )}
      </div>
    </>
  );
};

export default ClearstepWidget;
